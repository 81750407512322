<template>
    <div class="animated fadeIn">
    <div id="snackbar"></div>    
    <b-row>
      <b-col sm="12">
        <b-card>
          <div slot="header">
            <strong>Media </strong> <small>Form</small>
          </div>
          <b-form v-on:submit.prevent.self="submit">
          <b-col sm="3">
              <b-form-group>
                <label for="thumbnail_image">Upload Video Here</label>
                <!-- <b-form-input type="text" v-model="cinema.Thumbnail" id="thumbnail" placeholder="Thumbnail Image"></b-form-input> -->
                 <b-form-file required v-on:change="uploadMedia($event.target)" accept="video/mp4,video/x-m4v" ref="fileinput" id="fileInput" :plain="true"></b-form-file>
                 <h5>{{ media.path !== '' ? media.path : ''}}</h5>
              </b-form-group>
            </b-col>

          <b-row>
              <b-col sm="12">
                  <b-button type="submit" size="sm" variant="primary"><i class="fa fa-dot-circle-o"></i> Submit</b-button>
              </b-col>
          </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>    
</template>
<script>
import { Switch as cSwitch } from "@coreui/vue";
import UploadService from "@/services/UploadService";

export default {
  name: "Countries",
  components: {
    cSwitch
  },
  data() {
    return {
      media: {
        path: ""
      }
    };
  },

  methods: {
    uploadMedia: function(data) {
      let self = this;
      var formdata = new FormData();
      formdata.append("assets/videos", data.files[0]);
      // let res = await CinemaService.uploadFiles(formdata);
      UploadService.uploadFiles(formdata)
        .then(response => {
          const { data } = response;
          data.Message ? self.showMessageSnackbar(data.Message) : "";
          if (data.Status) {
            self.media.path = data.Path;
          }
        })
        .catch(error => {
          self.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>
<style>
</style>
